import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import AppIcons from '../../assets/images/icons';

import { Button } from 'react-bootstrap';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";


import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import './service.scss';
import 'animate.css';

const HomeSpaceOrganizing = () => {
    useEffect(() => {
        setTimeout(() => {
            document.body.classList.add('home-space-organizing-page');
        }, 0);
    });
    const [index, setIndex] = useState(-1);
    const [selectedPhoto, setSelectedPhoto] = useState([])


    const GalleryList = [
        {
            images: [
                {
                    src: AppIcons.serviceSlider1,
                },
                {
                    src: AppIcons.serviceSlider2,
                },
                {
                    src: AppIcons.serviceSlider3,
                },
                {
                    src: AppIcons.serviceSlider4,
                },
                {
                    src: AppIcons.serviceSlider5,
                },
                {
                    src: AppIcons.serviceSlider6,
                },
                {
                    src: AppIcons.serviceSlider7,
                },
            ],

        },
    ];


    return (
        <div id='app-main-content'>
            <div className='main-content-wrp f-wrp'>
                <div className="home-space-intro-wrapper f-wrp">
                    <div className="container">
                        <div className="home-space-intro f-wrp">
                            <div className="homeSpace-img-sec">
                                <div className='row' style={{ margin: '0' }}>
                                    <div className='col-lg-8 col-md-8 col-sm-8 col-xs-8 no-padding'>
                                        <h1>Home-space Organizing</h1>
                                        <p className='mob-p' style={{display: 'none'}}>Understanding the person living in a home is essential for design</p>
                                        <div className='row' style={{ margin: '0' }}>
                                            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6 no-padding'>
                                                <span style={{ height: '100%' }}>
                                                    <p className='pc-p'>Understanding the person living in a home is essential for design</p>
                                                    <img src={AppIcons.spaceOrganizing1} alt="home space Organizing" />
                                                </span>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6 no-padding'>
                                                <span style={{ height: '100%' }}><img src={AppIcons.spaceOrganizing2} alt="home space Organizing" /></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-4 col-xs-4 no-padding'>
                                        <span style={{ height: '100%' }}><img src={AppIcons.spaceOrganizing3} alt="home space Organizing" /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="homeSpace-con-sec">
                                <div className='homeSpace-con-box f-wrp'>
                                    <p><b>Organizing your home can be quite a challenge, right?</b></p>
                                    <p>Our team takes a personalized approach getting to know you and understanding your vision for each space.</p>
                                    <p>We are all about empathy, and we'll be there to guide you through the process of creating a wonderful system that matches your lifestyle.</p>
                                    <p>Let us show you the tricks of the trade to help you stay organized and on top of things!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='slider-gallery-wrp f-wrp'>
                    <div className='container'>
                        <div className='slider-gallery-sec f-wrp'>
                            <div className='slider-gallery-box'>
                                <p>The magic of the tidy touch</p>
                            </div>
                            <div className='service-slider-box'>
                                <Lightbox
                                    slides={selectedPhoto}
                                    open={index >= 0}
                                    index={index}
                                    close={() => setIndex(-1)}
                                    plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                                />

                                {GalleryList.map((element, key) => (
                                    <div className="img-gallery-wrp f-wrp" key={key}>
                                        {
                                            element.images.slice(0, 5).map((img, indexKey) => <span
                                                onClick={() => {
                                                    setSelectedPhoto(element.images)
                                                    setIndex(indexKey)
                                                }}
                                            >
                                                <img src={img.src} alt="icon" /></span>
                                            )
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='service-list-wrapper f-wrp'>
                    <div className='container'>
                        <div className='each-service-list f-wrp'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding'>
                                    <span className='service-list-img'>
                                        <img src={AppIcons.servListImg} alt='service' />
                                    </span>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding'>
                                    <div className='each-list-box f-wrp'>
                                        <h3>You might want to organize your...</h3>
                                        <ul>
                                            <li><p>Home office and study area</p></li>
                                            <li><p>Closet and wardrobe</p></li>
                                            <li><p>Kitchen and pantry</p></li>
                                            <li><p>Living room and entertainment system</p></li>
                                            <li><p>Bathroom and vanity</p></li>
                                            <li><p>Kid’s room and play area organization</p></li>
                                            <li><p>Garage and storage room</p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding'>
                                    <div className='each-list-box f-wrp'>
                                        <h3>Our services includes:</h3>
                                        <ul>
                                            <li><p>Customized space assessment and organization plan</p></li>
                                            <li><p>Decluttering and sorting belongings</p></li>
                                            <li><p>Efficient storage solutions</p></li>
                                            <li><p>Labeling and categorizing systems for easy maintenance</p></li>
                                            <li><p>Tips and guidance for maintaining an organized space long-term</p></li>
                                            <li><p>Personalized organization strategies based on individual preferences and lifestyle.</p></li>
                                            <li><p>Dedicated and professional organizing team to bring your vision to life</p></li>
                                            <li><p>Donation and disposal assistance for unwanted items</p></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap f-wrp' />
                <div className='contact-main-wrp f-wrp'>
                    <div className='container'>
                        <div className='contact-main-box f-wrp'>
                            <h3>Let us help you now!</h3>
                            <Button onClick={() => window.location = 'mailto:ocd.instyle@gmail.com'} className='sqr-btn'>Contact us</Button>
                        </div>
                    </div>
                </div>
                <div className='gap f-wrp' />
            </div>
        </div>
    )
}

export default HomeSpaceOrganizing;