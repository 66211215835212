import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import AppIcons from '../../assets/images/icons';
import { NavHashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import navIcon from '../../assets/images/icons/navIcon.svg';
import OCDLogo from '../../assets/images/OCD_logo.png';

import sofa from '../../assets/images/icons/sofa.svg';
import style from '../../assets/images/icons/style.svg';
import portfolio from '../../assets/images/icons/portfolio.svg';
import about from '../../assets/images/icons/about.svg';
import testimonial from '../../assets/images/icons/testimonial.svg';
import contact from '../../assets/images/icons/contact.svg';



import './header.scss';
const Header = () => {


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const location = useLocation();
    const [currentURL, setCurrentURL] = useState('')

    useEffect(() => {
        setCurrentURL(location.pathname);
    }, [location.pathname])


    return (
        <>

            <div className={`header-main-wrp f-wrp ${currentURL === '/' ? 'home-header' : 'custom-header'} ${currentURL === '/site-map' ? 'site-map-header' : null}`}>
                <div className='container'>
                    <div className='head-con-sec f-wrp'>
                        <div className='nav-wrp'>
                            <Link to={'/'}><h1 className='animate__animated animate__slideInDown'><img src={OCDLogo} alt='Logo' /> inStyle</h1></Link>
                        </div>
                        <div className='burger-prof-sec'>
                            <div>
                                <Button
                                    id="fade-button"
                                    aria-controls={open ? 'fade-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    className='burger-icon'
                                >
                                    <img className="nav" src={navIcon} alt="profile" /> &nbsp; &nbsp; <img className="profile" src={AppIcons.user} alt="profile" />
                                </Button>
                                <Menu
                                    id="fade-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'fade-button',
                                    }}
                                    className="menu-wrp"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    TransitionComponent={Fade}
                                >
                                    <MenuItem onClick={handleClose}><Link to={'/home-space-organizing'}><img className="nav" src={sofa} alt="profile" /> Organizing</Link></MenuItem>
                                    <MenuItem onClick={handleClose}><Link to={'/interior-styling'}><img className="nav" src={style} alt="profile" /> Styling</Link></MenuItem>
                                    <MenuItem onClick={handleClose}><Link to={'/portfolio'}><img className="nav" src={portfolio} alt="profile" /> Portfolio</Link></MenuItem>
                                    <MenuItem onClick={handleClose}><NavHashLink smooth to={'/#about'}><img className="nav" src={about} alt="profile" /> About</NavHashLink></MenuItem>
                                    <MenuItem onClick={handleClose}><NavHashLink smooth to={'/#testimonial'}><img className="nav" src={testimonial} alt="profile" /> Testimonials</NavHashLink></MenuItem>
                                    <MenuItem onClick={handleClose}><NavHashLink smooth to={'/#contact-us'}><img className="nav" src={contact} alt="profile" /> Contact us</NavHashLink></MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Header;