import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from './Components/LandingPage';
import SamplePage from './Features/SamplePage';
import Layout from './Layout';
import HomeSpaceOrganizing from './Components/ServicePages/homeSpaceOrganizing';
import InteriorStyling from './Components/ServicePages/interiorStyling';
import Portfolio from './Components/portfolio/portfolio';
import SiteMap from './Components/siteMap/siteMap';
const AppRoutes = () => {
    return (
        <Layout>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/home-space-organizing" element={<HomeSpaceOrganizing />} />
                <Route path="/interior-styling" element={<InteriorStyling />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/site-map" element={<SiteMap />} />
                
                
                <Route path="/sample-page" element={<SamplePage />} />
            </Routes>
        </Layout>
    )
}

export default AppRoutes;