import React from 'react';
import Main from '../Main';
const LandingPage = () => {
    const isLoggedIn = true;
    return (
        <div>
            {(isLoggedIn) ? <>

                <Main />
            </> : <div>Login section to implement</div>}
        </div>
    )
}

export default LandingPage;