import React, { useState } from 'react';
import { Link } from "@mui/material";
// import { Link } from 'react-router-dom';
import AppIcons from '../../assets/images/icons';
import { NavHashLink } from 'react-router-hash-link';
import aLittle from '../../assets/images/a-little.svg';
import ocd from '../../assets/images/OCD.svg';
import OCDInstyle from '../../assets/images/OCDInstyle.png';
import aboutIcon1 from '../../assets/images/icons/Icons-2.svg';
import aboutIcon2 from '../../assets/images/icons/Icons-1.svg';
import aboutIcon3 from '../../assets/images/icons/Icons.svg';
import close from '../../assets/images/icons/close.svg';

import comingSoon from '../../assets/images/comingSoon.png';


import quats from '../../assets/images/icons/quats.svg';

import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';


import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper";

import CountUp from 'react-countup';

import 'animate.css';


const Main = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    return (
        <div id='app-main-content'>
            <div className='main-content-wrp f-wrp'>
                <div className='page-main-banner section-title-foot f-wrp'>
                    <div className='container'>
                        <div className='banner-content f-wrp'>
                            <div className="f-wrp baner-con-box animate__animated animate__slideInLeft">
                                <h2>Because</h2>
                                <div className="ocd-letter-sec f-wrp">
                                    <span className='ocd-letter'><img className='little' src={aLittle} alt='a little' /><img className='ocd' src={ocd} alt='OCD' /></span>
                                </div>
                                <h3>goes a long way</h3>
                                <p>Get ready for the ultimate <b>home</b> transformation! <br></br>We are dedicated to creating the perfect space for you.</p>
                                <NavHashLink smooth to={'#service'}>
                                    <Button className='sqr-btn'>Discover more</Button>
                                </NavHashLink>
                            </div>
                            <div className='arch-img animate__animated animate__slideInRight'>
                                <img src={AppIcons.arch} alt='banner arch' />
                            </div>

                        </div>
                    </div>
                </div>
                <div className='service-main-sec section-title-foot f-wrp' id='service'>
                    <div className='container'>
                        <div className='service-sec-blk f-wrp'>
                            <h2>Our services</h2>
                            <div className='row'>
                                <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 no-padding'>
                                    <Link href="/home-space-organizing">
                                        <div className='each-service-blk f-wrp'>
                                            <div className="service-slider-sec f-wrp">
                                                <Swiper
                                                    slidesPerView={1}
                                                    spaceBetween={10}
                                                    loop={true}
                                                    autoplay={{
                                                        delay: 2500,
                                                        disableOnInteraction: false,
                                                    }}
                                                    pagination={{
                                                        clickable: true,
                                                        dynamicBullets: true,
                                                    }}
                                                    navigation={true}
                                                    modules={[Autoplay, Pagination, Navigation]}
                                                    className="servicce-slider"
                                                >
                                                    <SwiperSlide>
                                                        <img src={AppIcons.homeSpace1} alt="project1" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <img src={AppIcons.homeSpace2} alt="project1" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <img src={AppIcons.homeSpace3} alt="project1" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <img src={AppIcons.homeSpace4} alt="project1" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <img src={AppIcons.homeSpace5} alt="project1" />
                                                    </SwiperSlide>

                                                </Swiper>
                                            </div>
                                            <div className='slider-con-blk f-wrp'>
                                                <h3>Home-space organizing</h3>
                                                <p>We bring calm to chaos one space at a time.</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 no-padding'>
                                    <Link href="/interior-styling">
                                        <div className='each-service-blk f-wrp'>
                                            <div className="service-slider-sec f-wrp">
                                                <Swiper
                                                    slidesPerView={1}
                                                    spaceBetween={10}
                                                    loop={true}
                                                    autoplay={{
                                                        delay: 2500,
                                                        disableOnInteraction: false,
                                                    }}
                                                    pagination={{
                                                        clickable: true,
                                                        dynamicBullets: true,
                                                    }}
                                                    navigation={true}
                                                    modules={[Autoplay, Pagination, Navigation]}
                                                    className="servicce-slider"
                                                >
                                                    <SwiperSlide>
                                                        <img src={AppIcons.Deco1} alt="project1" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <img src={AppIcons.Deco2} alt="project1" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <img src={AppIcons.Deco3} alt="project1" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <img src={AppIcons.Deco4} alt="project1" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <img src={AppIcons.Deco5} alt="project1" />
                                                    </SwiperSlide>

                                                </Swiper>
                                            </div>
                                            <div className='slider-con-blk f-wrp'>
                                                <h3>Interior styling</h3>
                                                <p>Enchanting spaces, timeless Elegance.</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 no-padding'>
                                    <Link onClick={handleShow}>
                                        <div className='each-service-blk f-wrp'>
                                            <div className="service-slider-sec f-wrp">
                                                <Swiper
                                                    slidesPerView={1}
                                                    spaceBetween={10}
                                                    loop={true}
                                                    autoplay={{
                                                        delay: 2500,
                                                        disableOnInteraction: false,
                                                    }}
                                                    pagination={{
                                                        clickable: true,
                                                        dynamicBullets: true,
                                                    }}
                                                    navigation={true}
                                                    modules={[Autoplay, Pagination, Navigation]}
                                                    className="servicce-slider"
                                                >
                                                    <SwiperSlide>
                                                        <img src={AppIcons.product} alt="project1" />
                                                    </SwiperSlide>
                                                </Swiper>
                                            </div>
                                            <div className='slider-con-blk f-wrp'>
                                                <h3>Products</h3>
                                                <p>Unleash the art of living.</p>
                                            </div>
                                        </div>
                                    </Link>
                                    <Modal show={show} onHide={handleClose}>
                                        <Modal.Body>
                                            <Button variant="secondary" onClick={handleClose}>
                                                <img src={close} alt='close' />
                                            </Button>
                                            <span><img src={comingSoon} alt='coming soon' /></span></Modal.Body>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap f-wrp'></div>

                <div className='portfolio-main-sec section-title-foot f-wrp'>
                    <div className='container-fluid'>
                        <div className='portfolio-sec-blk f-wrp'>
                            <div className='portfolio-sec-row'>
                                <div className='portfolio-sec-col-4'>
                                    <div className='portfolio-content-blk f-wrp'>
                                        <h3>Portfolio</h3>
                                        <p>Our portfolio features a  range of projects, including contemporary and minimalist designs, rustic and vintage-inspired spaces, and bold and eclectic styles. We take pride in our attention to detail and commitment to using sustainable materials and practices whenever possible.</p>
                                        <Link href="/portfolio"><Button className='sqr-btn'>See our portfolio</Button></Link>
                                    </div>
                                </div>
                                <div className='portfolio-sec-col-8'>
                                    <div className='portfolio-img-wrp f-wrp' style={{ display: 'flex' }}>
                                        <span><img src={AppIcons.port1} alt="portfolio" /></span>
                                        <span><img src={AppIcons.port2} alt="portfolio" /></span>
                                        <span><img src={AppIcons.port3} alt="portfolio" /></span>
                                        <span><img src={AppIcons.port4} alt="portfolio" /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap f-wrp'></div>
                <div className='aboutus-main-sec section-title-foot f-wrp' id='about'>
                    <div className='abt-logo-wrp f-wrp'>
                        <div className='container'>
                            <div className='abt-logo-content f-wrp'>
                                <ul>
                                    <li><h3>About</h3></li>
                                    <li><span><img src={OCDInstyle} alt="abt-logo" /></span></li>
                                </ul>
                                <p>Au revoir to chaos and hello to an organized oasis that's both functional and stylish. </p>
                            </div>
                        </div>
                    </div>
                    <div className='counter-blk-wrp f-wrp'>
                        <div className='container'>
                            <div className='counter-sec-content f-wrp'>
                                <div className='each-counter-blk'>
                                    <label><CountUp
                                        end={25}
                                        enableScrollSpy
                                        scrollSpyOnce suffix="+"
                                        duration={2.75} /></label>
                                    <p>Years of experience</p>
                                </div>
                                <div className='each-counter-blk'>
                                    <label><CountUp
                                        end={145}
                                        enableScrollSpy
                                        scrollSpyOnce
                                        suffix="+"
                                        duration={2.75} /></label>
                                    <p>Projects</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='icon-blk-wrp f-wrp'>
                        <div className='container'>
                            <div className='icon-sec-content f-wrp'>
                                <div className='each-icon-blk f-wrp'>
                                    <span><img src={aboutIcon1} alt="Smooth workflow" /></span>
                                    <h4>Smooth workflow</h4>
                                </div>
                                <div className='each-icon-blk f-wrp'>
                                    <span><img src={aboutIcon2} alt="Elevated design" /></span>
                                    <h4>Elevated design</h4>
                                </div>
                                <div className='each-icon-blk f-wrp'>
                                    <span><img src={aboutIcon3} alt="Master stylists " /></span>
                                    <h4>Master stylists</h4>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap f-wrp'></div>

                <div className='testimonial-main-sec section-title-foot f-wrp' id='testimonial'>
                    <div className='container'>
                        <div className='testimonial-content-wrp f-wrp'>
                            <div className='left-testi-box f-wrp animate__animated animate__slideInLeft'>
                                <span><img src={AppIcons.testLeft} alt="testimonial" /></span>
                            </div>
                            <div className='middle-testi-box f-wrp'>
                                <h5>Testimonials</h5>
                                <span className='quats'><img src={quats} alt="quats" /></span>
                                <p>OCD inStyle is an absolute gem! Carina, has truly mastered the art of blending elegance and homely comfort. From the moment she stepped into my home, I knew I was in good hands.</p>
                                <p>Carina's keen eye for design is unmatched. She effortlessly creates spaces that are not only stylish and high-end but also feel warm and inviting. The way she combines luxury pieces with personal touches is simply magical. I feel finally my home now reflects my personality now.</p>
                                <p>Not only they helped me with the styling, but with home organizing too. My cluttered spaces are now beautifully streamlined, making everyday life a breeze. I'm so thankful for this guys!!</p>

                                <b>Stella P.</b>
                                <p>Newport Beach, CA</p>

                            </div>
                            <div className='right-testi-box animate__animated animate__slideInRight f-wrp'>
                                <span><img src={AppIcons.testRight} alt="testimonial" /></span>
                            </div>
                        </div>

                    </div>

                </div>

                <div className='contact-main-sec section-title-foot f-wrp' id='contact-us'>
                    <div className='container'>
                        <div className='contct-con-wrp f-wrp'>
                            {/* <div className='contact-title'>
                                <h4>Contact us</h4>
                            </div> */}
                            <div className='contact-content'>
                                <h4>Email us at</h4>
                                <span onClick={() => window.location = 'mailto:ocd.instyle@gmail.com'}>ocd.instyle@gmail.com</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main;