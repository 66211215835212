import React, { useState } from 'react';
import AppIcons from '../../assets/images/icons';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";


import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";


import './portfolio.scss';
import 'animate.css';

const Portfolio = () => {

    const [index, setIndex] = useState(-1);
    const [selectedPhoto, setSelectedPhoto] = useState([])

    const Portfolio1 = [
        {
            images: [
                {
                    src: AppIcons.portfolio1_1,
                },
                {
                    src: AppIcons.portfolio1_2,
                },
                {
                    src: AppIcons.portfolio1_3,
                },
                {
                    src: AppIcons.portfolio1_4,
                },
                {
                    src: AppIcons.portfolio1_5,
                },

            ],

        },
    ];
    const Portfolio2 = [
        {
            images: [
                {
                    src: AppIcons.portfolio2_1,
                },
                {
                    src: AppIcons.portfolio2_2,
                },
                {
                    src: AppIcons.portfolio2_3,
                },
                {
                    src: AppIcons.portfolio2_4,
                },                
            ],

        },
    ];
    const Portfolio3 = [
        {
            images: [
                {
                    src: AppIcons.portfolio3_1,
                },
                {
                    src: AppIcons.portfolio3_2,
                },
                {
                    src: AppIcons.portfolio3_3,
                },
                {
                    src: AppIcons.portfolio3_4,
                },
                {
                    src: AppIcons.portfolio3_5,
                },

            ],

        },
    ];
    const Portfolio4 = [
        {
            images: [
                {
                    src: AppIcons.portfolio4_1,
                },
                {
                    src: AppIcons.portfolio4_2,
                },
                {
                    src: AppIcons.portfolio4_3,
                },
                {
                    src: AppIcons.portfolio4_4,
                },

            ],

        },
    ];


    return (
        <div id='app-main-content'>
            <div className='main-content-wrp f-wrp'>
                <div className='portfolio-main-wrp f-wrp'>
                    <div className='portfolio-header-wrp f-wrp'>
                        <div className="container">
                            <div className='portfolio-header-sec f-wrp'>
                                <h1>Portfolio</h1>
                            </div>
                        </div>
                    </div>

                    <div className='portfolio-grd-wrp f-wrp'>
                        <div className='container'>
                            <div className='portfolio-grid-header f-wrp'>
                                <div className='title'>
                                    <h3>From idea <br />to implementation</h3>
                                </div>
                                <div className='discription'>
                                    <p>With our passion for design and dedication our portfolio represents our commitment to creating beautiful spaces.</p>
                                </div>
                            </div>
                            <div className='each-folio-content f-wrp'>
                                <Lightbox
                                    slides={selectedPhoto}
                                    open={index >= 0}
                                    index={index}
                                    close={() => setIndex(-1)}
                                    plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                                />
                                <div className='each-folio-grid f-wrp'>
                                    <div className='slider-gallery-sec f-wrp'>
                                        <div className='slider-gallery-box'>
                                            <p>The tidy <br />touch</p>
                                        </div>
                                        <div className='service-slider-box'>
                                            {Portfolio1.map((element, key) => (
                                                <div className="img-gallery-wrp f-wrp" key={key}>
                                                    {
                                                        element.images.slice(0, 5).map((img, indexKey) => <span
                                                            onClick={() => {
                                                                setSelectedPhoto(element.images)
                                                                setIndex(indexKey)
                                                            }}
                                                        >
                                                            <img src={img.src} alt="icon" /></span>
                                                        )
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className='each-folio-grid f-wrp'>
                                    <div className='slider-gallery-sec f-wrp'>
                                        <div className='slider-gallery-box'>
                                            <p>Garage <br />oasis</p>
                                        </div>
                                        <div className='service-slider-box'>
                                            {/* <Lightbox
                                                slides={selectedPhoto}
                                                open={index >= 0}
                                                index={index}
                                                close={() => setIndex(-1)}
                                                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                                            /> */}

                                            {Portfolio2.map((element, key) => (
                                                <div className="img-gallery-wrp f-wrp" key={key}>
                                                    {
                                                        element.images.slice(0, 4).map((img, indexKey) => <span
                                                            onClick={() => {
                                                                setSelectedPhoto(element.images)
                                                                setIndex(indexKey)
                                                            }}
                                                        >
                                                            <img src={img.src} alt="icon" /></span>
                                                        )
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className='each-folio-grid f-wrp'>
                                    <div className='slider-gallery-sec f-wrp'>
                                        <div className='slider-gallery-box'>
                                            <p>Garage <br />transformation</p>
                                        </div>
                                        <div className='service-slider-box'>
                                            {/* <Lightbox
                                                slides={selectedPhoto}
                                                open={index >= 0}
                                                index={index}
                                                close={() => setIndex(-1)}
                                                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                                            /> */}

                                            {Portfolio3.map((element, key) => (
                                                <div className="img-gallery-wrp f-wrp" key={key}>
                                                    {
                                                        element.images.slice(0, 5).map((img, indexKey) => <span
                                                            onClick={() => {
                                                                setSelectedPhoto(element.images)
                                                                setIndex(indexKey)
                                                            }}
                                                        >
                                                            <img src={img.src} alt="icon" /></span>
                                                        )
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className='each-folio-grid f-wrp'>
                                    <div className='slider-gallery-sec f-wrp'>
                                        <div className='slider-gallery-box'>
                                            <p>Garage <br />revive</p>
                                        </div>
                                        <div className='service-slider-box'>
                                            {/* <Lightbox
                                                slides={selectedPhoto}
                                                open={index >= 0}
                                                index={index}
                                                close={() => setIndex(-1)}
                                                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                                            /> */}

                                            {Portfolio4.map((element, key) => (
                                                <div className="img-gallery-wrp f-wrp" key={key}>
                                                    {
                                                        element.images.slice(0, 4).map((img, indexKey) => <span
                                                            onClick={() => {
                                                                setSelectedPhoto(element.images)
                                                                setIndex(indexKey)
                                                            }}
                                                        >
                                                            <img src={img.src} alt="icon" /></span>
                                                        )
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Portfolio;