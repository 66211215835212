import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import AppIcons from '../../assets/images/icons';

import { Button } from 'react-bootstrap';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";


import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";


import './service.scss';
import 'animate.css';

const InteriorStyling = () => {
    useEffect(() => {
        setTimeout(() => {
            document.body.classList.add('home-space-organizing-page');
        }, 0);
    });

    const [index, setIndex] = useState(-1);
    const [selectedPhoto, setSelectedPhoto] = useState([])

    const GalleryList = [
        {
            images: [
                {
                    src: AppIcons.interiorServiceSlider1,
                },
                {
                    src: AppIcons.interiorServiceSlider2,
                },
                {
                    src: AppIcons.interiorServiceSlider3,
                },
                {
                    src: AppIcons.interiorServiceSlider4,
                },
                {
                    src: AppIcons.interiorServiceSlider5,
                },
                {
                    src: AppIcons.interiorServiceSlider6,
                },
            ],

        },
    ];


    return (
        <div id='app-main-content'>
            <div className='main-content-wrp f-wrp'>
                <div className='interior-styling-wrp f-wrp'>
                    <div className='interior-home-banner f-wrp'>
                        <div className="container">
                            <span>
                                <img className='banner' src={AppIcons.interiorBanner} alt='interior banner' />
                                <img className='bannerBorder' src={AppIcons.interiorBannerBorder} alt='interior banner' />
                            </span>
                        </div>
                    </div>

                    <div className='interior-content-wrapper f-wrp'>
                        <div className="container">
                            <div className='interior-content-box f-wrp'>
                                <p><b>Your home is a realm of endless possibilities, a blank canvas.</b><br />Together, we'll embark on a journey to transform it into a sanctuary that captivates the senses.</p>
                                <p>With an artist's touch and an eye for detail, we'll craft a space that resonates with your unique style. From subtle accents to striking focal points, every element will be curated.</p>
                                <p>Our Interior Styling service will turn your vision into a reality infused with charm and character. <b>At the end of the process you'll be able to immerse yourself in a world of refined aesthetics and comfort. </b></p>
                            </div>
                        </div>
                    </div>

                    <div className='service-list-wrapper f-wrp'>
                        <div className='container'>
                            <div className='interior-service-wrp f-wrp'>

                                <div className='row' style={{ margin: '0' }}>
                                    <div className='col-lg-2 col-md-2 col-sm-12 col-xs-12 no-padding'>
                                        <span className='lamb-light'><img src={AppIcons.lightLamb} alt='Light Lamb' /></span>
                                    </div>
                                    <div className='col-lg-10 col-md-10 col-sm-12 col-xs-12 no-padding'>

                                        <div className='each-service-list f-wrp'>
                                            <div className='row' style={{ margin: '0' }}>
                                                <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12 no-padding'>
                                                    <span className='service-list-img'>
                                                        <img src={AppIcons.coffeePots} alt='Coffee Pots' />
                                                    </span>
                                                </div>
                                                <div className='col-lg-8 col-md-8 col-sm-12 col-xs-12 no-padding'>
                                                    <div className='each-list-box f-wrp'>
                                                        <h3>Our service includes</h3>
                                                        <ul>
                                                            <li><p>Comprehensive project analysis and budget optimization</p></li>
                                                            <li><p>Collaborative integration with architects</p></li>
                                                            <li><p>Specification of materials, products, and color palette </p></li>
                                                            <li><p>Effective project management and supervision</p></li>
                                                            <li><p>Space planning with CAD/REVIT</p></li>
                                                            <li><p>Custom window treatments</p></li>
                                                            <li><p>Upholstery and bed treatments re-design</p></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding'>
                                                    <div className='each-list-box f-wrp'>
                                                        <h3>You might be....</h3>
                                                        <ul>
                                                            <li><p>Moving into a new home and seeking to create a personalized space.</p></li>
                                                            <li><p>Preparing to sell your property and wanting to stage it to maximize its appeal.</p></li>
                                                            <li><p>Going through a life transition and desiring a fresh space that accommodates to your new phase.</p></li>
                                                            <li><p>Renovating a space and needing guidance in selecting the right materials, colors, and furniture.</p></li>
                                                            <li><p>Hosting frequent gatherings and desiring a well-designed entertaining area that impresses your guests.</p></li>
                                                            <li><p>Feeling overwhelmed with the current home decor and seeking to bring balance, harmony, and style.</p></li>
                                                            <li><p>Simply wanting to refresh your home's look and ambiance to enhance comfort and enjoyment on a daily basis.</p></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='slider-gallery-wrp f-wrp'>
                        <div className='container'>
                            <div className='slider-gallery-sec f-wrp'>
                                <div className='slider-gallery-box'>
                                    <p>Enchanted Fascination</p>
                                </div>
                                <div className='service-slider-box'>
                                    <Lightbox
                                        slides={selectedPhoto}
                                        open={index >= 0}
                                        index={index}
                                        close={() => setIndex(-1)}
                                        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                                    />

                                    {GalleryList.map((element, key) => (
                                        <div className="img-gallery-wrp f-wrp" key={key}>
                                            {
                                                element.images.slice(0, 5).map((img, indexKey) => <span
                                                    onClick={() => {
                                                        setSelectedPhoto(element.images)
                                                        setIndex(indexKey)
                                                    }}
                                                >
                                                    <img src={img.src} alt="icon" /></span>
                                                )
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='contact-main-wrp f-wrp'>
                        <div className='container'>
                            <div className='contact-main-box f-wrp'>
                                <h3>I’m ready to upgrade my living</h3>
                                <Button onClick={() => window.location = 'mailto:ocd.instyle@gmail.com'} className='sqr-btn'>Contact us</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap f-wrp' />
            </div>
        </div>
    )
}

export default InteriorStyling;