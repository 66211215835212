import React from 'react';
import { Link } from "@mui/material";
import AppIcons from '../../assets/images/icons';
import mihapp from '../../assets/images/mihapp.png';
import './foot.scss';
const Footer = () => {
    return (
        <div className='copy-foot f-wrp'>
            <div className='container'>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="copy-txt f-wrp">
                            <p>© 2023 OCD inStyle ·Terms · <Link href="/site-map" >Site map</Link></p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className='dev-blk'>
                            <p style={{ textAlign: 'center', fontWeight: '400', color: '#312A33', marginTop: '5px' }}>Built by &nbsp;<i style={{ cursor: 'pointer', display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => window.open('https://mihapp.co/', '_blank')}><span style={{ textDecoration: "none", fontStyle: 'normal', cursor: 'pointer', color: '#312A33' }}>mihapp.co</span> &nbsp;<img style={{ display: 'inline-block', width: '25px', maxWidth: '25px', }} src={mihapp} alt="mihapp" /></i></p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className='social-list f-wrp'>
                            <ul>
                                <li><span onClick={() => window.open('/', '_blank')}><img src={AppIcons.facebook} alt="facebook" /></span></li>
                                <li><span onClick={() => window.open('/', '_blank')}><img src={AppIcons.twitter} alt="twitter" /></span></li>
                                <li><span onClick={() => window.open('/')}><img src={AppIcons.insta} alt="instagram" /></span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer;