import React from 'react';
import { Link } from "@mui/material";
// import AppIcons from '../../assets/images/icons';
// import { Link } from 'react-router-dom';

import { NavHashLink } from 'react-router-hash-link';

// import { Button } from 'react-bootstrap';


import './siteMap.scss';



const SiteMap = () => {


    return (
        <div id='app-main-content'>
            <div className='main-content-wrp f-wrp'>

                <div className='siteMap-main-wrp f-wrp'>
                    <div className='siteMap-header-wrp f-wrp'>
                        <div className="container">
                            <div className='siteMap-header-sec f-wrp'>
                                <h1>Site map</h1>
                            </div>
                        </div>
                    </div>
                    <div className='siteMap-content-wrp f-wrp'>
                        <div className="container">
                            <div className='each-siteMap-sec f-wrp'>
                                <div className='row'>
                                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                                        <div className='each-siteMap-list'>
                                            <h3>Services</h3>
                                            <ul>

                                                <li><Link href="/home-space-organizing" > Home-space organizing</Link></li>
                                                {/* <li><Link href="/" >Products</Link></li> */}
                                                <li><Link href="/interior-styling" >Interior styling</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                                        <div className='each-siteMap-list'>
                                            <h3>Portfolio</h3>
                                            <ul>
                                                <li><Link href="/portfolio" > The tidy touch</Link></li>
                                                <li><Link href="/portfolio" > Garage oasis</Link></li>
                                                <li><Link href="/portfolio" > Garage transformation</Link></li>
                                                <li><Link href="/portfolio" > Garage revive</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='each-siteMap-sec f-wrp'>
                                <div className='row'>
                                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                                        <div className='each-siteMap-list'>
                                            <h3>Testimonials</h3>
                                            <ul>
                                                <li><NavHashLink smooth to={'/#testimonial'}>Client testimonials</NavHashLink></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                                        <div className='each-siteMap-list'>
                                            <h3>About us</h3>
                                            <ul>
                                                <li><NavHashLink smooth to={'/#about'}>Achievements</NavHashLink></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                                        <div className='each-siteMap-list'>
                                            <h3>Contact us</h3>
                                            <ul>
                                                <li><Link onClick={() => window.location = 'mailto:ocd.instyle@gmail.com'} >Email us</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='gap f-wrp' style={{height: '30px', background: '#F5F3F5'}} />
                </div>
            </div>
        </div>
    )
}

export default SiteMap;