const AppIcons = {
    sampleIcon: require('../icons/sample.ico'),
    alien: require('./alien.png'),
    arch: require('../Arch.png'),
    service1: require('../Img.png'),
    service2: require('../Img-1.png'),
    service3: require('../Img-2.png'),
    port1: require('../port1.png'),
    port2: require('../port2.png'),
    port3: require('../port3.png'),
    port4: require('../port4.png'),
    testLeft: require('../test-left.png'),
    testRight: require('../test-right.png'),
    
    Deco1: require('../Service/Deco1.png'),
    Deco2: require('../Service/Deco2.png'),
    Deco3: require('../Service/Deco3.png'),
    Deco4: require('../Service/Deco4.png'),
    Deco5: require('../Service/Deco5.png'),
    homeSpace1: require('../Service/homeSpace1.png'),
    homeSpace2: require('../Service/homeSpace2.png'),
    homeSpace3: require('../Service/homeSpace3.png'),
    homeSpace4: require('../Service/homeSpace4.png'),
    homeSpace5: require('../Service/homeSpace5.png'),
    product: require('../Service/product.png'),

    facebook: require('../icons/facebook.png'),
    insta: require('../icons/insta.png'),
    twitter: require('../icons/twitter.png'),

    user: require('../icons/user.png'),

    HomeSpaceOrganizing: require('../homeSpaceOrganizing/HomeSpaceOrganizing.png'),

    spaceOrganizing1: require('../homeSpaceOrganizing/spaceOrganizing1.png'),
    spaceOrganizing2: require('../homeSpaceOrganizing/spaceOrganizing2.png'),
    spaceOrganizing3: require('../homeSpaceOrganizing/spaceOrganizing3.png'),

    serviceSlider1: require('../homeSpaceOrganizing/serviceSlider/serviceSlider1.png'),
    serviceSlider2: require('../homeSpaceOrganizing/serviceSlider/serviceSlider2.png'),
    serviceSlider3: require('../homeSpaceOrganizing/serviceSlider/serviceSlider3.png'),
    serviceSlider4: require('../homeSpaceOrganizing/serviceSlider/serviceSlider4.png'),
    serviceSlider5: require('../homeSpaceOrganizing/serviceSlider/serviceSlider5.png'),
    serviceSlider6: require('../homeSpaceOrganizing/serviceSlider/serviceSlider6.png'),
    serviceSlider7: require('../homeSpaceOrganizing/serviceSlider/serviceSlider7.png'),

    servListImg: require('../homeSpaceOrganizing/servListImg.png'),
    interiorBanner: require('../interiorStyling/interiorBanner.png'),
    interiorBannerBorder: require('../interiorStyling/interiorBannerBorder.png'),
    coffeePots: require('../interiorStyling/coffeePots.png'),
    lightLamb: require('../interiorStyling/lightLamb.png'),
    interiorServiceSlider1: require('../interiorStyling/serviceSlider/serviceSlider1.png'),
    interiorServiceSlider2: require('../interiorStyling/serviceSlider/serviceSlider2.png'),
    interiorServiceSlider3: require('../interiorStyling/serviceSlider/serviceSlider3.png'),
    interiorServiceSlider4: require('../interiorStyling/serviceSlider/serviceSlider4.png'),
    interiorServiceSlider5: require('../interiorStyling/serviceSlider/serviceSlider5.png'),
    interiorServiceSlider6: require('../interiorStyling/serviceSlider/serviceSlider6.png'),

    portfolio1_1: require('../portfolio/portfolioSlide1/portfolio1.png'),
    portfolio1_2: require('../portfolio/portfolioSlide1/portfolio2.png'),
    portfolio1_3: require('../portfolio/portfolioSlide1/portfolio3.png'),
    portfolio1_4: require('../portfolio/portfolioSlide1/portfolio4.png'),
    portfolio1_5: require('../portfolio/portfolioSlide1/portfolio5.png'),

    portfolio2_1: require('../portfolio/portfolioSlide2/portfolio1.png'),
    portfolio2_2: require('../portfolio/portfolioSlide2/portfolio2.png'),
    portfolio2_3: require('../portfolio/portfolioSlide2/portfolio3.png'),
    portfolio2_4: require('../portfolio/portfolioSlide2/portfolio4.png'),
    
    portfolio3_1: require('../portfolio/portfolioSlide3/portfolio1.png'),
    portfolio3_2: require('../portfolio/portfolioSlide3/portfolio2.png'),
    portfolio3_3: require('../portfolio/portfolioSlide3/portfolio3.png'),
    portfolio3_4: require('../portfolio/portfolioSlide3/portfolio4.png'),
    portfolio3_5: require('../portfolio/portfolioSlide3/portfolio5.png'),

    portfolio4_1: require('../portfolio/portfolioSlide4/portfolio1.png'),
    portfolio4_2: require('../portfolio/portfolioSlide4/portfolio2.png'),
    portfolio4_3: require('../portfolio/portfolioSlide4/portfolio3.png'),
    portfolio4_4: require('../portfolio/portfolioSlide4/portfolio4.png'),
    



}

export default AppIcons;
